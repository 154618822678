var checkinApp = angular.module('checkinApp', ['ngRoute', 'ngResource', 'angularCSS', 'ngCookies']);



checkinApp.run(['$rootScope', '$templateCache', function($rootScope, $templateCache) {

    $rootScope.$on('$routeChangeStart', function(event, next, current) {

        if (typeof(current) !== 'undefined'){

            $templateCache.remove(current.templateUrl);

        }

    });

}]);



checkinApp.config(['$locationProvider', function($locationProvider) {

  $locationProvider.hashPrefix('');

}]);



checkinApp.directive('uidatepicker', function() {

    return {

        restrict: 'A',

        require: 'ngModel',

        link: function(scope, element, attrs, ctrl) {			

            $(element).datepicker({

                dateFormat: 'dd/mm/yy',

				changeMonth: true,

      			changeYear: true,

				yearRange: "-110:+10",

                onSelect: function(date) {

                    ctrl.$setViewValue(date);

                    ctrl.$render();

                    scope.$apply();

                }

            });

        }

    };

});



checkinApp.directive('nomlogocamping', function() {

  return {

    template: '<div class="row"><div class="col-sm-12 flexLogo"><img ng-if="rutaLogo" ng-src="{{rutaLogo}}" alt="logoCamping" height=50px><p class="nomCampingLogo">{{nomCamping}}</p></div></div>'

  };

});



checkinApp.config(['$routeProvider', function($routeProvider) {

	var idCamping = getCookie('idCamping');
	var numReserva = getCookie('numReserva');
	var email = getCookie('email');

	if (window.location.hash == '#/login' && idCamping && numReserva && email) {
		window.location.href = '#/login/' + idCamping + '/' + email + '/' + numReserva;
	}

		$routeProvider.

			when('/login/:camping', {

				templateUrl: 'app/components/login/login.html',

				controller: 'loginCtrl'

			}).

			when('/login/:camping/:idioma', {

				templateUrl: 'app/components/login/login.html',

				controller: 'loginCtrl'

			}).

			when('/login/:camping/:email/:reserva', {

				templateUrl: 'app/components/login/login.html',

				controller: 'loginCtrl'

			}).

			when('/login/:camping/:idioma/:email/:reserva', {

				templateUrl: 'app/components/login/login.html',

				controller: 'loginCtrl'

			}).

			when('/loginGrupo/:grupo', {

				templateUrl: 'app/components/loginGrupo/loginGrupo.html',

				controller: 'loginGrupoCtrl'

			}).

			when('/loginGrupo/:grupo/:idioma', {

				templateUrl: 'app/components/loginGrupo/loginGrupo.html',

				controller: 'loginGrupoCtrl'

			}).

			when('/loginGrupo/:grupo/:email/:reserva', {

				templateUrl: 'app/components/loginGrupo/loginGrupo.html',

				controller: 'loginGrupoCtrl'

			}).

			when('/loginGrupo/:grupo/:idioma/:email/:reserva', {

				templateUrl: 'app/components/loginGrupo/loginGrupo.html',

				controller: 'loginGrupoCtrl'

			}).

			when('/resumenEstancia', {

				templateUrl: 'app/components/resumenEstancia/resumenEstancia.html',

				controller: 'resumenEstanciaCtrl'

			}).	
			when('/firmaElectronica', {

				templateUrl: 'app/components/firmaElectronica/firmaElectronica.html',

				controller: 'firmaElectronicaCtrl'

			}).				

			when('/fichaCliente', {

				templateUrl: 'app/components/fichaCliente/fichaCliente.html',

				controller: 'fichaClienteCtrl'

			}).				

			when('/acompanantes', {

				templateUrl: 'app/components/acompanantes/acompanantes.html',

				controller: 'acompanantesCtrl'

			}).		

			when('/vehiculos', {

				templateUrl: 'app/components/vehiculos/vehiculos.html',

				controller: 'vahiculosCtrl'

			}).

			when('/extras', {

				templateUrl: 'app/components/ventaCruzada/ventaCruzada.html',

				controller: 'ventaCruzadaCtrl'

			}).				

			when('/pago', {

				templateUrl: 'app/components/newPago/newPago.html',

				controller: 'NewPagoCtrl'

			}).

			when('/fixPagosTPV', {

				templateUrl: 'app/components/fixPagosTPV/fixPagosTPV.html',

				controller: 'fixPagosTPVCtrl'

			}).

			otherwise({

				redirectTo: '/login'

			});

}]);





checkinApp.directive('toNumber', function () {

    return {

        require: 'ngModel',

        link: function (scope, elem, attrs, ctrl) {

            ctrl.$parsers.push(function (value) {

                return parseFloat(value || '');

            });

        }

    };

});



checkinApp.directive('cleanInput', function() {

	return {

	  require: 'ngModel',

	  restrict: 'A',

	  link: function(scope, element, attrs, modelCtrl) {

	    modelCtrl.$parsers.push(function(inputValue) {

	      if (inputValue == undefined)

	        return ''

	      // cleanInputValue = inputValue.replace(/[^\w\s]/gi, '');

	      cleanInputValue = inputValue.replace(/&/g, '').replace(/</g, '').replace(/>/g, '');

	      if (cleanInputValue != inputValue) {

	        modelCtrl.$setViewValue(cleanInputValue);

	        modelCtrl.$render();

	      }

	      return cleanInputValue;

	    });

	  }

	}

});



checkinApp.directive('consent', ['$cookies', function ($cookies) {

  return {

    scope: {},

    template:

      '<div style="position: relative; z-index: 1000">' +

      '<div style="background: #ccc; position: fixed; bottom: 0; left: 0; right: 0" ng-hide="consent()">' +

      ' <a href="" ng-click="consent(true)">I\'m cookie consent</a>' +

      '</div>' +

      '</div>',

    controller: ['$scope', function ($scope) {

      var _consent = $cookies.get('consent');

      $scope.consent = function (consent) {

        if (consent === undefined) {

          return _consent;

        } else if (consent) {

          $cookies.put('consent', true);

          _consent = true;        

        }

      };

    }]

  }

}]);