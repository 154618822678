angular.module('checkinApp').controller('loginCtrl', ['$scope', '$location', '$resource', '$routeParams', '$css', 'serveiTraduccions', 'serveiScopeShare', 'serveiApi', '$timeout', function($scope, $location, $resource, $routeParams, $css, serveiTraduccions, serveiScopeShare, serveiApi, $timeout) {

    "use strict";
    var self = this;

    var hayVentaCruzada = false;

    $scope.showWindow = false;

    $scope.rutaLogo = '';

    $scope.estadoR = 0;

    $scope.documentoFirmado = 0;

    $scope.firmaDocumentoCamping = 0;

    $scope.idReservaReal = '';



    self.init = function() {

        console.log('init loginCtrl');

        var idCamping;

        var parametres;

        var url;

        

        serveiScopeShare.setInfoReserva('');

        serveiScopeShare.setDoneCliente(false);

        serveiScopeShare.setDoneAcompanantes(false);

        serveiScopeShare.setDoneVehiculos(false);

        serveiScopeShare.setEstadoReserva('');

        serveiScopeShare.setPrestaciones([]);



        //Recupero idioma

        var userLang = $routeParams.idioma;

        if (userLang == undefined || userLang === '') {

            //recupero quin es el idioma amb el que esta configurat el navegador del client

            userLang = navigator.language || navigator.userLanguage;

        }

        userLang = userLang.substring(0, 2);

        var arrayIdiomes = serveiScopeShare.getListaIdiomas();



        var arrayIdiomes = serveiScopeShare.getListaIdiomas();



        if (arrayIdiomes.indexOf(userLang) != -1) { //esta definit

            serveiScopeShare.setIdioma(userLang);

        } else { //no esta definit

            serveiScopeShare.setIdioma('es');

        }

        $.datepicker.setDefaults($.datepicker.regional[serveiScopeShare.getIdioma()]);



        //Recupero traduccions generals

        serveiTraduccions.getTranslation($scope, 'general', serveiScopeShare.getIdioma());

        serveiScopeShare.setTraduccionsGeneral($scope.translation);



        //recupero la traduccio del text segons el idioma del navegador

        serveiTraduccions.getTranslation($scope, 'login', serveiScopeShare.getIdioma());



        //recuperar el parametre de la url  

        idCamping       = $routeParams.camping;

        var email       = $routeParams.email;

        var numReserva  = $routeParams.reserva;

        //Firma de documento
        //Saber si el camping tiene la opcion de firmar documento 
        serveiApi.getFirmaDocumento(idCamping).then(function(data) {
    
            $scope.firmaDocumentoCamping = parseInt(data);
            serveiScopeShare.setFirmarDocumentoCamping(parseInt(data));
            
        });

        //Saber si el documento esta firmado
        serveiApi.getEstadoFirma(idCamping, numReserva, email)
        .then(function(data) {

            $scope.documentoFirmado = parseInt(data);
            serveiScopeShare.setDocumentoFirmado(parseInt(data));

        });

        //obtengo el id real de reserva
        serveiApi.getIdReserva(idCamping, numReserva, email).then(function(data) {
            $scope.idReservaReal = parseInt(data);
            serveiScopeShare.setIdReservaReserva(parseInt(data));
        });

        //include de les css personalitzades de cada camping

        // $css.add('css/custom_'+idCamping+'.css');

        $css.add('../'+idCamping+'_upload/custom_'+idCamping+'.css');



        //Guardo el idCamping al scopeShare

        serveiScopeShare.setIdCamping(idCamping);



        // llanço la actualitzacio dels parametres del camping

        // var idCamping = serveiScopeShare.getIdCamping();



        // parametres = $.param({ idCamping: idCamping });

        // serveiApi.parseXML(parametres)

        //     .then(function(data) {



            //recuperar el text personalitzat del camping

            parametres = $.param({ fun: 'getCabeceraCamping', id: idCamping, lang: serveiScopeShare.getIdioma()});

            serveiApi.getCabeceraCamping(parametres)

                .then(function(data) {

                    $scope.textoPersonalizado = data;

                })

                .catch(function(err) {

            });



            //recupero si els acompanants i els vehicles son obligatoris

            parametres = $.param({ id_camping: idCamping });

            serveiApi.getSeccionsObligatories(parametres)

                .then(function(data) {

                    serveiScopeShare.setDemanarAcompanantes(data[0]);

                    serveiScopeShare.setDemanarVehicles(data[1]);

                    serveiScopeShare.setHayVentaCruzada(parseInt(data[2]));

                    hayVentaCruzada = parseInt(data[2]);

                    serveiScopeShare.setPais(data[3]);

                })

                .catch(function(err) {

            });



            //recupero el peu de pagina del camping

            parametres = $.param({ fun: 'getPeuPagina', id: idCamping, lang: serveiScopeShare.getIdioma() });

            serveiApi.getPeuPagina(parametres)

                .then(function(data) {

                    serveiScopeShare.setPeuPagina(data);

                })

                .catch(function(err) {

            });



            //Descripció venta creuada

            parametres = $.param({ fun: 'getDescExtras', id: idCamping, lang: serveiScopeShare.getIdioma() });

            serveiApi.getDescExtras(parametres)

                .then(function(data) {

                    serveiScopeShare.setDescExtras(data);

                })

                .catch(function(err) {

            });



            //Explicació TPV

            parametres = $.param({ fun: 'getDescTPV', id: idCamping, lang: serveiScopeShare.getIdioma() });

            serveiApi.getDescTPV(parametres)

                .then(function(data) {

                    serveiScopeShare.setDescTPV(data);

                })

                .catch(function(err) {

            });



            parametres = $.param({ fun: 'getInfoTPV', id_camping: idCamping});

            serveiApi.getInfoTPV(parametres)

                .then(function(data) {

                    serveiScopeShare.setInfoTPV(data);

                })

                .catch(function(err) {

            });



            //recupero el nom del camping

            parametres = $.param({ idCamping: idCamping });

            serveiApi.getNomCamping(parametres)

                .then(function(data) {

                    $scope.textNomCamping = data;

                    serveiScopeShare.setNomCamping(data);

                })

                .catch(function(err) {

            });



            parametres = $.param({ id_camping: idCamping });

            serveiApi.getMailCamping(parametres)

                .then(function(data) {

                    serveiScopeShare.setMailCamping(data);

                })

                .catch(function(err) {

            });



            serveiApi.getLogo(idCamping)

            .then(function(data) {

                if(data != undefined && data !== '') {

                    $scope.rutaLogo = data;

                    serveiScopeShare.setLogoCamping(data);

                }

            });



            $scope.diasMinimos = '';

            serveiApi.getDiasMinimos(idCamping)

            .then(function(data) {

                if(data != undefined && data !== '') {

                    $scope.diasMinimos = data;                                        

                }

            });


            $scope.diasPagoObligado = '';

            serveiApi.getDiasPagoObligado(idCamping)

            .then(function(data) {

                if(data != undefined && data !== '') {

                    $scope.diasPagoObligado = data;

                }

            });
            //Miro si m'han passat email i reserva per parametres

            if(email != undefined && numReserva != undefined) {

                serveiScopeShare.setMailCliente(email);

                serveiScopeShare.setIdReserva(numReserva);



                self.accederReserva();

            } else {

                $scope.showWindow = true;

            }

        //     })

        //     .catch(function(err) {

        //         console.log(err);

        // });

    };



    $scope.loginForm = function(form) {



        if (form.$valid) { //el formualari esta validat, comprobo que la reserva existeix a la base de dades



            serveiScopeShare.setMailCliente($scope.email);

            serveiScopeShare.setIdReserva($scope.numReserva);



            self.accederReserva();

        }

    };

    function saveInCookie(idCamping, numReserva, email) {
        console.log('saveInCookie, idCamping: ' + idCamping + ', numReserva: ' + numReserva + ', email: ' + email);
        var date = new Date();
        date.setTime(date.getTime() + (1 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
        document.cookie = "idCamping=" + idCamping + expires + "; path=/";
        document.cookie = "numReserva=" + numReserva + expires + "; path=/";
        document.cookie = "email=" + email + expires + "; path=/";
    }

    self.accederReserva = function() {

        //recuperar el parametre de la url  

        var idCamping       = $routeParams.camping;

        $scope.numReserva   = serveiScopeShare.getIdReserva();

        $scope.email        = serveiScopeShare.getMailCliente();        

        // Save idCamping, numReserva and email in cookie for 1 hour
        saveInCookie(idCamping, $scope.numReserva, $scope.email);

        serveiApi.getReserva(idCamping, $scope.numReserva, $scope.email).then(function(data) {

            

            if (data != 'false') {

            //Actualizamos la id Reserva por si hemos entrado con localiador u otra cosa

            serveiScopeShare.setIdReserva(data[1]);



                var estado = parseInt(data[7]);

                if(estado == 0) {

                    var llegada = moment(data[6].Estancia.Del,'D/M/YYYY').startOf('day');

                    var hoy = moment().startOf('day');

                    var diffDays = llegada.diff(hoy, 'days');   

                    if($scope.diasMinimos != '' && $scope.diasMinimos != '0') {

                        if(diffDays < parseInt($scope.diasMinimos)) {

                            $scope.showWindow = true;

                            $scope.reservaCaducada = true;

                            return;

                        }

                    }
                    
                    if($scope.diasPagoObligado != '' && $scope.diasPagoObligado != 0) {

                        if(diffDays <= parseInt($scope.diasPagoObligado)) {

                            serveiScopeShare.setPagoObligado(true);

                        } else {

                            serveiScopeShare.setPagoObligado(false);

                        }

                    }

                }



                serveiScopeShare.setInfoReserva(data);

                var objAnteriores = {cliente: self.objectFromString(data[16]), acompanantes: self.objectFromString(data[17]), 

                    vehiculos: self.objectFromString(data[18]), ventaCruzada: self.objectFromString(data[20])};

                serveiScopeShare.setDatosAnteriores(objAnteriores);                



                //Comprovo si li correspon alguna prestació per mostrar el pas nou

                var rawPrestaciones = [];

                var prestaciones = [];

                var algunaDisponible = false;

                if (hayVentaCruzada) {

                    if (data[6].Estancia.IdTarifa != undefined) {



                        var idTarifa = data[6].Estancia.IdTarifa;

                        var infoEstancia = data[6].Estancia;                        



                        serveiApi.getVentasCruzadas(idCamping).then(function(data) {

                            

                            if (data.Prestaciones != undefined && data.Prestaciones != null) {

                                rawPrestaciones = data.Prestaciones.Prestacion;

                                if(!(Object.prototype.toString.call(rawPrestaciones) === '[object Array]')) {

                                    rawPrestaciones = [rawPrestaciones];

                                }

                            } else {

                                rawPrestaciones = [];

                            }



                            var precioEstancia = parseFloat(infoEstancia.Importes.Estancia.replace(',', '.'));

                            var numNoches = getNoches(infoEstancia.Del, infoEstancia.AL);

                            var todoOK;

                            for(var i = 0; i < rawPrestaciones.length; i++) {

                                todoOK = false;



                                if(rawPrestaciones[i] != undefined) {

                                    //Check tarifa

                                    if (rawPrestaciones[i].TarifasEstancia.IdTarifa != null) {

                                        if(!(Object.prototype.toString.call(rawPrestaciones[i].TarifasEstancia.IdTarifa) === '[object Array]')) {

                                            rawPrestaciones[i].TarifasEstancia.IdTarifa = [rawPrestaciones[i].TarifasEstancia.IdTarifa];

                                        }

                                        if ((rawPrestaciones[i].TarifasEstancia.IdTarifa).indexOf(idTarifa) >= 0) {

                                            todoOK = true;

                                        }

                                    }

                                }



                                //Check periodos



                                var inicio = infoEstancia.Del;

                                var splitted = inicio.split("/");

                                inicio = ""+splitted[2]+splitted[1]+splitted[0];

                                var final = infoEstancia.AL;

                                splitted = final.split("/");

                                final = ""+splitted[2]+splitted[1]+splitted[0];                            

                                

                                if (todoOK) {

                                    todoOK = false;                                    

                                    if (rawPrestaciones[i].Periodos.Periodo != null) {

                                        if(!(Object.prototype.toString.call(rawPrestaciones[i].Periodos.Periodo) === '[object Array]')) {

                                            rawPrestaciones[i].Periodos.Periodo = [rawPrestaciones[i].Periodos.Periodo];

                                        }

                                        for(var j = 0; j < rawPrestaciones[i].Periodos.Periodo.length; j++) {

                                            if (parseInt(rawPrestaciones[i].Periodos.Periodo[j].Inicio) <= parseInt(inicio) &&

                                                parseInt(inicio) <= parseInt(rawPrestaciones[i].Periodos.Periodo[j].Fin)) {

                                                todoOK = true;

                                                break;

                                            }

                                        }

                                    }

                                }



                                //Check condiciones

                                if (todoOK) {

                                    todoOK = false

                                    if (rawPrestaciones[i].Condicion != null && parseInt(rawPrestaciones[i].Condicion) == 2) {

                                        //Compruebo precio de estancia

                                        var minimo = parseFloat(rawPrestaciones[i].Tabn1);

                                        var maximo = parseFloat(rawPrestaciones[i].Tabn2);

                                        //var precioEstancia = parseFloat(infoEstancia.Importes.Saldo);
                                        var precioEstancia = parseFloat(infoEstancia.Importes.Estancia);



                                        if (minimo <= precioEstancia && maximo >= precioEstancia) {

                                            todoOK = true;

                                        }

                                    } else {

                                        todoOK = true;

                                    }

                                }



                                //Todo OK, afegeixo prestacio



                                var nom, desc;

                                var idioma = serveiScopeShare.getIdioma();

                                var idiomasDisponibles = ["es", "ca", "fr", "it", "nl", "en", "de", "da", "pt"];

                                if (idiomasDisponibles.indexOf(idioma) < 0) {

                                    idioma = "es";

                                }                                



                                if(todoOK) {



                                    if((rawPrestaciones[i]).hasOwnProperty("Imagen") && (""+rawPrestaciones[i].Imagen) != "[object Object]") {

                                        rawPrestaciones[i].Imagen = idCamping+"_upload/" + rawPrestaciones[i].Imagen;

                                    } else {

                                        rawPrestaciones[i].Imagen = "";

                                    }



                                    if(!(rawPrestaciones[i]).hasOwnProperty("Tipo")) {

                                        rawPrestaciones[i].Tipo = "0";

                                    }



                                    nom = rawPrestaciones[i].Nombre[idioma];

                                    if (nom == "" || nom == undefined) {

                                        nom = rawPrestaciones[i].Nombre.es;

                                    }

                                    desc = rawPrestaciones[i].Descripcion[idioma];

                                    if (desc == "" || desc == undefined) {

                                        desc = rawPrestaciones[i].Descripcion.es;

                                    }



                                    var precioPrest = "0";                                    

                                    var porTramos = false;

                                    if((rawPrestaciones[i].Precio).hasOwnProperty("PorTramos") && rawPrestaciones[i].Precio.PorTramos == 1) {

                                        porTramos = true;

                                        if(!(Object.prototype.toString.call(rawPrestaciones[i].Precio.PrecioTramo) === '[object Array]')) {

                                            rawPrestaciones[i].Precio.PrecioTramo = [rawPrestaciones[i].Precio.PrecioTramo];

                                        }

                                        

                                        for (var j = 0; j < rawPrestaciones[i].Precio.PrecioTramo.length; ++j) {

                                            if (parseFloat(rawPrestaciones[i].Precio.PrecioTramo[j].Desde) <= precioEstancia && precioEstancia <= parseFloat(rawPrestaciones[i].Precio.PrecioTramo[j].Hasta)) {                                                

                                                precioPrest = rawPrestaciones[i].Precio.PrecioTramo[j].Total;

                                                break;

                                            }

                                        }                                        

                                    } else {

                                        if (rawPrestaciones[i].Precio.PorNoches == "1") {

                                            precioPrest = rawPrestaciones[i].Precio.Total * numNoches;

                                        } else {

                                            precioPrest = rawPrestaciones[i].Precio.Total;

                                        }                                        

                                    }



                                    algunaDisponible = true;

                                    prestaciones.push(

                                        {

                                            id: rawPrestaciones[i].Id,

                                            descripcion: desc,

                                            nombre: nom,

                                            imagen: rawPrestaciones[i].Imagen,

                                            precio: precioPrest,

                                            tipo: rawPrestaciones[i].Tipo,

                                            porTramos: porTramos,

                                            porNoches: rawPrestaciones[i].Precio.PorNoches == "1",

                                            seleccionada: 0

                                        });

                                }

                            }

                            

                            serveiScopeShare.setHayVentaCruzada(algunaDisponible);

                            serveiScopeShare.setPrestaciones(prestaciones);

                            redirigir();

                        });

                    } else {

                        //LA RESERVA VIENE SIN INFO DE TARIFA

                        serveiScopeShare.setHayVentaCruzada(false);

                        redirigir();

                    }

                } else {

                    //NO HAY VENTAS CRUZADAS

                    serveiScopeShare.setHayVentaCruzada(false);

                    redirigir();

                }

            } else {

                $scope.showWindow = true;

                $scope.reservaNoExiste = true;

            }

        });

    };



    function getNoches(del, al) {

        var a = moment(del,'D/M/YYYY');

        var b = moment(al,'D/M/YYYY');

        var diffDays = b.diff(a, 'days');



        return diffDays;

    }



    var intentosTPV = 0;

    function redirigir() {

        //Saber el estado de reserva si es 0 o 1
        serveiApi.getEstadoReserva(serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(),  serveiScopeShare.getMailCliente)
        .then(function(data) {


            $scope.estadoR = parseInt(data);
            //Comprobar si tenim pendent tpv si vinc de link
            if ($routeParams.email != undefined && $routeParams.email != '' &&

            $routeParams.reserva != undefined && $routeParams.reserva != '') {

            serveiApi.checkEstadoTPV(serveiScopeShare.getIdCamping(), $routeParams.reserva, $routeParams.email)

                    .then(function(data) {

                        if (data != undefined && data.hayTPV == true) {

                            if(data.cadenaRespuesta != "") {

                                if( $scope.firmaDocumentoCamping === 1 && $scope.documentoFirmado === 0  && $scope.estadoR == 1){
                                    $location.path('/firmaElectronica'); 
                                }else{
                                    $location.path('/resumenEstancia');
                                }

                            } else {

                                intentosTPV++

                                if (intentosTPV < 3) {

                                    $timeout(redirigir, 3000);

                                } else {

                                    // if( $scope.firmaDocumentoCamping === 1 && $scope.documentoFirmado === 0 ){
                                    //     $location.path('/firmaElectronica'); 
                                    // }else{
                                        $location.path('/resumenEstancia');
                                // }

                                }

                            }

                        } else {

                            if( $scope.firmaDocumentoCamping === 1 && $scope.documentoFirmado === 0 && $scope.estadoR == 1){
                                $location.path('/firmaElectronica'); 
                            }else{
                                $location.path('/resumenEstancia');
                            }
                        }

                    });

        } else {

            //Si el documento está firmado vamos a resumen, sino tendremos que terminar la firma
            var firmado = 0;
            serveiApi.getEstadoFirma(serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(),  serveiScopeShare.getMailCliente)
            .then(function(data) {

                firmado = parseInt(data);
                $scope.documentoFirmado = parseInt(data);

                //Firma Documento
                var firmarCheckin = 0;
                serveiApi.getFirmaDocumento(serveiScopeShare.getIdCamping()).then(function(data) {


                    firmarCheckin = parseInt(data);


                    if( firmado === 0 && $scope.estadoR === 1 && firmarCheckin === 1 ){
                        $location.path('/firmaElectronica');
                    }else {
                        $location.path('/resumenEstancia');
                    }

                });
            
            }).catch(function(err) {
                // Tratar el error)
            });

        // $location.path('/resumenEstancia');

        }

            
        }).catch(function(err) {
            // Tratar el error)
        });





    }



    self.init();



    $scope.test = function() {

        return 'hola';

    };



    self.escapeSpecialChars = function(jsonString) {



            return jsonString.replace(/\n/g, "\\n")

                .replace(/\r/g, "\\r")

                .replace(/\t/g, "\\t")

                .replace(/\f/g, "\\f");

                // .replace(/\\/g, "\\");



        };

    

    self.objectFromString = function(input) {

        if (input !== "" && input !== null && input !== undefined) {            

            try {

                return JSON.parse(self.escapeSpecialChars(input));

            } catch(e) {

            }

        }

        return null;

    };

}]);

