angular.module('checkinApp').controller('resumenEstanciaCtrl', ['$scope', '$http', '$location', '$sce', '$resource', '$routeParams', '$css', 'serveiTraduccions', 'serveiScopeShare', 'serveiDecode', 'serveiApi', function($scope, $http, $location, $sce, $resource, $routeParams, $css, serveiTraduccions, serveiScopeShare, serveiDecode, serveiApi) {

    "use strict";    



    $scope.visibleGuardar = false;

    $scope.pagadoCheckin = true;



    $scope.mostrarExitoTPV = false;

    $scope.mostrarCanceladoTPV = false;

    $scope.mostrarFracasoTPV = false;

    $scope.rutaPDF = '#';
    $scope.rutaPDFLili = '#';
    $scope.rutaPDFTemp = '#';
    $scope.rutaPDFTempGoogle  = '#';
    $scope.idRealReserva = '';
    $scope.documentoFirmado = 0;
    $scope.idReservaEtapa = serveiScopeShare.getIdReserva();


    init();



    function init() {



        var idCamping = serveiScopeShare.getIdCamping();

        if (idCamping == null || parseInt(idCamping) == 0) {

            $location.path('/login');

            return;

        }

        //prevenimos que no se pueda dar atras en el navegador
        //window.onpopstate = function (e) { window.history.forward(); }
        

        //obtengo el id real de reserva
        serveiApi.getIdReserva(idCamping, serveiScopeShare.getIdReserva(), serveiScopeShare.getMailCliente()).then(function(data) {
            // TODO Cambiar a variable global
            // $scope.rutaPDFTemp = $sce.trustAsResourceUrl('https://checkin-pre.unicamp.com/pdf_firmados/'+data+'.pdf');
            $scope.rutaPDFTemp = $sce.trustAsResourceUrl('https://checkin.unicamp.com/pdf_firmados/'+data+'.pdf');
            // $scope.rutaPDFTempGoogle = $sce.trustAsResourceUrl('https://docs.google.com/gview?embedded=true&url=https://checkin-pre.unicamp.com/pdf_firmados/'+data+'.pdf');$scope.rutaPDFTemp = $sce.trustAsResourceUrl('https://checkin-pre.unicamp.com/pdf_firmados/'+data+'.pdf');
            $scope.rutaPDFTempGoogle = $sce.trustAsResourceUrl('https://docs.google.com/gview?embedded=true&url=https://checkin.unicamp.com/pdf_firmados/'+data+'.pdf');

            $scope.idRealReserva = data;
            
            // console.log('idRealReserva');
            // console.log($scope.idRealReserva);
        });

        //Si el documento está firmado vamos a resumen, sino tendremos que terminar la firma
        serveiApi.getEstadoFirma(idCamping, serveiScopeShare.getIdReserva(),  serveiScopeShare.getMailCliente)
        .then(function(firmado) {
            $scope.documentoFirmado = parseInt(firmado);
        });


        $scope.visibleIniciarCheckin = true;



        //include de les css personalitzades de cada camping

        $css.add('../'+serveiScopeShare.getIdCamping()+'_upload/custom_'+serveiScopeShare.getIdCamping()+'.css');



        //recupero la traduccio del text segons el idioma del navegador

        serveiTraduccions.getTranslation($scope, 'resumen', serveiScopeShare.getIdioma());

        $scope.transGeneral = serveiScopeShare.getTraduccionsGeneral();

        $scope.peuPagina = serveiScopeShare.getPeuPagina();

        $scope.hayCruzada = serveiScopeShare.getHayVentaCruzada();

        $scope.rutaLogo = serveiScopeShare.getLogoCamping();

        $scope.nomCamping = serveiScopeShare.getNomCamping();



        //recupero la info de la resva de la base de dades

        var email = serveiScopeShare.getMailCliente();

        var idReserva = serveiScopeShare.getIdReserva();



        serveiApi.getReserva(idCamping, idReserva, email).then(function(data) {



            //recupero la reserva de la bdd

            $scope.dataReserva = data;

            $scope.estadoReserva = serveiScopeShare.getEstadoReserva();            



            //Per ara el checkin es fa tot de cop!!!

            $scope.visibleIniciarCheckin = true;

            $scope.visibleVerResumen = false;

            $scope.visibleContinuarCheckin = false;



            if ($scope.estadoReserva == undefined || $scope.estadoReserva == '') {

                // estado reserva

                $scope.estadoReserva = parseInt(data[7]);

            }



            if ($scope.estadoReserva == 1 || $scope.estadoReserva == 3 || $scope.estadoReserva == 4) {

                $scope.visibleIniciarCheckin = false;

                $scope.visibleVerResumen = true;

                $scope.visibleContinuarCheckin = false;

                                //Creamos el PDF, si el documento no ha sido firmado
                                if( $scope.documentoFirmado == 0 ){

                                    var formData = { 
                                        reservaPDF: serveiScopeShare.getIdReserva(), 
                                        campingPDF : serveiScopeShare.getIdCamping(),
                                        idiomaPDF : serveiScopeShare.getIdioma()
                                    };
                    
                                    var postData = 'myData='+JSON.stringify(formData);
                    
                                    $http({
                                            method : 'POST',
                                            // url : 'https://checkin-pre.unicamp.com/api/showPdfAjax.php',
                                            url : 'https://checkin.unicamp.com/api/showPdfAjax.php',
                                            data: postData,
                                            headers : {'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*'}  
                                        }).then(function successCallback(response) {
                                            // this callback will be called asynchronously
                                            // when the response is available
                    
                                            $scope.rutaPDF = $scope.rutaPDFTemp;
                                            $scope.rutaPDFLili = $sce.trustAsResourceUrl('https://docs.google.com/gview?embedded=true&url='+$scope.rutaPDFTemp);
                                                              
                                    }, function errorCallback(error) {
                                            // called asynchronously if an error occurs
                                            // or server returns response with an error status.
                                    });
                            
                                }else{
                                    $scope.rutaPDF = $scope.rutaPDFTemp;
                                    $scope.rutaPDFLili = $sce.trustAsResourceUrl('https://docs.google.com/gview?embedded=true&url='+$scope.rutaPDFTemp);
                                }

                //Actualizamos la etapa resumen cuando hemos hecho todo el checkin
                serveiApi.setEtapaResumenfinal($scope.idReservaEtapa, 1).then(function(data){
                });

                               
                                //Creamos el PDF
                
                                
                
            }else{

                //Actualizamos la etapa resumen inicial
                serveiApi.setEtapaReserva($scope.idReservaEtapa, '', 1, 0, 0, 0, 0, 0, 0, 0).then(function(data){
                });

            } //else {

                // if (data[19] == 0) { //iniciar checkin

                //     $scope.visibleIniciarCheckin = true;

                //     $scope.visibleVerResumen = false;

                //     $scope.visibleContinuarCheckin = false;

                // } else if (data[19] == 4) { //esta tot fet, redirigeixo al resumen total

                //     $scope.visibleIniciarCheckin = false;

                //     $scope.visibleVerResumen = true;

                //     $scope.visibleContinuarCheckin = false;

                // }else {

                //     $scope.visibleIniciarCheckin = false;

                //     $scope.visibleVerResumen = false;

                //     $scope.visibleContinuarCheckin = true;

                // }

            // }



            if ($scope.estadoReserva > 0) {

                $scope.visibleGuardar = true;

                $scope.noRealizada = false;

            } else {                

                $scope.noRealizada = true;

            }

           

            var infoReserva;

            var infoPrestaciones;

            if ($scope.estadoReserva > 0) { //Si ja esta feta ho afago del xml final

                infoReserva = data[15]['Estancia'];

                infoPrestaciones = data[15]['Prestaciones'];                

                if (data[6]['Estancia']['Importes']['Pagado'] == infoReserva['Importes']['Pagado']) {

                    $scope.pagadoCheckin = false;

                }

            } else {

                $scope.pagadoCheckin = false;

                infoReserva = data[6]['Estancia'];

                infoPrestaciones = data[6]['Prestaciones'];

            }



            $scope.fechaRealizacion     = serveiDecode.stringToDate(data[9]);

            $scope.del                  = infoReserva['Del'];

            $scope.al                   = infoReserva['AL'];

            $scope.numeroReserva        = infoReserva['Numero'];

            $scope.tarifa               = infoReserva['Tarifa'];

            $scope.aPagar               = (Math.round(parseFloat(infoReserva['Importes']['Estancia'].replace(",",".")) * 100) / 100).toFixed(2);

            $scope.nombreCliente        = data[6]['Cliente']['Nombre'] + ' ' + data[6]['Cliente']['Apellidos'];

            if (infoPrestaciones != undefined) {

                if(!(Object.prototype.toString.call(infoPrestaciones['Prestacion']) === '[object Array]')) {

                    infoPrestaciones['Prestacion'] = [infoPrestaciones['Prestacion']];

                }

                $scope.prestaciones = infoPrestaciones['Prestacion'];

                $scope.hayPrestaciones = true;

                

            } else {

                $scope.prestaciones = [];

                $scope.hayPrestaciones = false;

            }



            if ($scope.hayPrestaciones) {

                $scope.totalPrestaciones = data[15]['TotalPrestaciones'];

                $scope.totalTotal = ((Math.round(parseFloat(infoReserva['Importes']['Estancia'].replace(",",".")) * 100) / 100) +

                (Math.round(parseFloat(data[15]['TotalPrestaciones'].replace(",",".")) * 100) / 100)).toFixed(2);

            } else {

                $scope.totalTotal = $scope.aPagar;

            }



            $scope.yaPagado     = (Math.round(parseFloat(data[6]['Estancia']['Importes']['Pagado'].replace(",",".")) * 100) / 100).toFixed(2); 

            $scope.Pendiente    = (Math.round(parseFloat(infoReserva['Importes']['Saldo'].replace(",",".")) * 100) / 100).toFixed(2);

            $scope.pagado       = (Math.round(parseFloat(infoReserva['Importes']['Pagado'].replace(",",".")) * 100) / 100).toFixed(2);



            if ($scope.pagadoCheckin) {

                //Si tenemos número de tarjeta es que tenemos su info

                if(typeof data[15]['Pago']['NumeroTarjeta'] === 'string' || data[15]['Pago']['NumeroTarjeta'] instanceof String) {

                    $scope.esTarjeta = true;

                    $scope.tipoPago = $scope.transGeneral.tarjeta;

                    $scope.numeroTarjeta = "****" + (data[15]['Pago']['NumeroTarjeta']).slice(-4);

                    $scope.titular = data[15]['Pago']['Titular'];

                } else {

                    $scope.esTarjeta = false;

                    $scope.tipoPago = $scope.transGeneral.transferencia;

                }

            }



            $scope.rubricas = '';
            var idioma = serveiScopeShare.getIdioma();
            var rubricas = infoReserva['Rubricas']['Rubrica'];

            //22/08/2024 se corrige error cuando no era array
            if (!Array.isArray(rubricas)) {
                rubricas = [rubricas];
            }
            
            //preparo les rubriques
            for (var i = 0; i < rubricas.length; i++) {
            //legacy code
                /*for (var i = 0; i < infoReserva['Rubricas']['Rubrica'].length; i++) {                
                //$scope.rubricas += infoReserva['Rubricas']['Rubrica'][i]['Cantidad'] + ' ' + decodeRubricas(infoReserva['Rubricas']['Rubrica'][i]['Descripcion']) + ', ';    
                $scope.rubricas += infoReserva['Rubricas']['Rubrica'][i]['Cantidad'] + ' ' + infoReserva['Rubricas']['Rubrica'][i]['Descripcion'] + ', ';*/
                //22/08/2024 revisión para las traducciones
                var descripcion = rubricas[i]['Descripcion'];
                var idiomas = rubricas[i]['Idiomas'];

                if (idiomas.hasOwnProperty(idioma)) {
                    descripcion = idiomas[idioma];
                }

                $scope.rubricas += rubricas[i]['Cantidad'] + ' ' + descripcion + ', ';                              

            }

            $scope.rubricas = $scope.rubricas.substring(0, $scope.rubricas.length - 2);



            var mail = serveiScopeShare.getMailCliente();

            serveiApi.getPasoReserva(idCamping, $scope.numeroReserva,  mail)

            .then(function(data) {

                var paso = parseInt(data);



                serveiApi.checkEstadoTPV(idCamping, $scope.numeroReserva, serveiScopeShare.getMailCliente())

                .then(function(data) {

                    if (data != undefined) {

                        if(data.hayTPV == true) {

                            if(data.cadenaRespuesta != "") {

                                if (parseInt(data.respuesta) == 0 && data.autorizacion != undefined && data.autorizacion != "") {

                                    $scope.mostrarExitoTPV = true;

                                } else {

                                    $scope.mostrarCanceladoTPV = true;

                                }

                            } else {

                                $scope.mostrarFracasoTPV = true;

                                $scope.idCompraTPV = data.idCompra;

                            }

                        }

                    }

                });



                if(paso == 5 && $scope.estadoReserva == 1) {

                    serveiApi.enviarMail($scope.numeroReserva, idCamping, serveiScopeShare.getIdioma(), serveiScopeShare.getMailCamping(), mail, $scope.idRealReserva)

                    .then(function(data) {
                    });

                    serveiApi.setPasoReserva(idCamping, $scope.numeroReserva,  mail, 6)

                    .then(function(data) {

                    });

                }

            });

        });

    }



    function redirigir() {



        var path = '';



        if (($scope.dataReserva[19] > 0)) { //guardo la info del cliente principal      



            serveiScopeShare.setInfoClientePrincipal(JSON.parse($scope.dataReserva[16]));

            serveiScopeShare.setDoneCliente(true);

            path = '/acompanantes';

        }



        if (($scope.dataReserva[19] > 1)) { //guardo la info dels acompanants



            serveiScopeShare.setInfoAcompanantes(JSON.parse($scope.dataReserva[17]));

            serveiScopeShare.setDoneAcompanantes(true);



            if (serveiScopeShare.getDemanarVehicles() == "1") {

                path = '/vehiculos';

            } else {

                path = '/pago';

            }

        }



        if ($scope.dataReserva[19] > 2) { //guardo la info dels vehicles



            serveiScopeShare.setInfoVehiculos(JSON.parse($scope.dataReserva[18]));

            serveiScopeShare.setDoneVehiculos(true);

            path = '/pago';

        }



        $location.path(path);

    }



    $scope.iniciarCheckin = function() {

        serveiScopeShare.getDoneCliente(false);

        $location.path('/fichaCliente');

    };



    $scope.continuarCheckin = function() {

        redirigir();

    };



    $scope.goAcompanantes = function() {

        $location.path('/acompanantes');

    };



    $scope.goVehiculos = function() {

        $location.path('/vehiculos');

    };



    $scope.goPago = function() {

        $location.path('/pago');

    };



    $scope.mostrarPDF = function() {



        var url = './api/showPdf.php';

        var form = $('<form target="_blank" action="' + url + '" method="post">' +

          '<input type="hidden" name="reservaPDF" value="' + serveiScopeShare.getIdReserva() + '" />' +

          '<input type="hidden" name="campingPDF" value="' + serveiScopeShare.getIdCamping() + '" />' +

          '<input type="hidden" name="idiomaPDF" value="' + serveiScopeShare.getIdioma() + '" />' +

          '<input type="hidden" name="sendMail"     value="false" />' +

          '<input type="hidden" name="mailCamping"  value="" />' +

          '<input type="hidden" name="mailCliente"  value="" />' +

          '</form>');

        $('body').append(form);        

        form.submit();

    };



    function decodeRubricas(rubrica) {

        return serveiDecode.decode(rubrica);

    }



}]);