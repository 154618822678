angular.module('checkinApp').controller('NewPagoCtrl', ['$scope', '$sce', '$location', '$resource', '$routeParams', '$css', 'serveiTraduccions', 'serveiScopeShare', 'serveiDecode', 'serveiApi', function ($scope,$sce, $location, $resource, $routeParams, $css,
                                                                 serveiTraduccions, serveiScopeShare, serveiDecode, serveiApi) {
    "use strict";

    $scope.indexSeleccionat = 0;
    $scope.arrayDisabled = [];
    $scope.meses = [];
    $scope.years = [];
    $scope.condicionesVenta = false;
    $scope.emailCamping = '';
    $scope.fechaHoy = "";
    $scope.procesadoOK = false;
    $scope.pagoProcesado = false;
    $scope.showSpinner = false;
    $scope.horaLlegada = "12";
    $scope.minutosLlegada = "00";
    $scope.preguntasRGPD = [];
    $scope.respuestasRGPD = [];
    $scope.pagoOpcionalSeleccionado = false;

    $scope.estadoReserva = 0;
    $scope.documentoFirmado = 0;

    //Nuevos campos para manejar el rango de horario de llegada al camping
    $scope.horaLlegada1 = [];
    $scope.numReserva = serveiScopeShare.getIdReserva();

    $scope.personalitzedConditions = '';

    init();

    function init() {

        const lang = serveiScopeShare.getIdioma();
        const property = serveiScopeShare.getIdCamping();
        const filePath = './condiciones_finales/' + property + '_' + lang + '.html';

        fetch(filePath).then(function (response) {
            if (response.status === 200) {
                $scope.$apply(function () {
                    $scope.personalitzedConditions = $sce.trustAsResourceUrl(filePath);
                });
            } else {
                $scope.$apply(function () {
                    $scope.personalitzedConditions = '';
                });
            }
        }).catch(function (error) {
            console.error(error);
            $scope.$apply(function () {
                $scope.personalitzedConditions = '';
            });
        });


        var firmarDocumento = serveiScopeShare.getFirmarDocumentoCamping();


        //Nuevos cambios para manejar rangos de horarios de llegada al camping
        serveiApi.getHoraLlegada(serveiScopeShare.getIdCamping())
            .then(function (data) {

                var horariosLlegada = [data[0]];
                var horaLimite = parseInt(data[1]);
                var horaMinima = parseInt(data[0]);

                for (var index = horaMinima + 1; index <= horaLimite; index++) {
                    horariosLlegada.push(index);
                }
                $scope.horaLlegada1 = horariosLlegada;
            });

        serveiApi.getEstadoReserva(serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(), serveiScopeShare.getMailCliente)
            .then(function (data) {
                if (parseInt(data) > 0) {

                    $scope.estadoReserva = parseInt(data);

                    //Si el documento está firmado vamos a resumen, sino tendremos que terminar la firma
                    serveiApi.getEstadoFirma(serveiScopeShare.getIdCamping(), serveiScopeShare.getIdReserva(), serveiScopeShare.getMailCliente)
                        .then(function (firmado) {
                            $scope.documentoFirmado = parseInt(firmado);

                            if ($scope.estadoReserva > 0 && $scope.documentoFirmado === 1) {
                                $location.path('/resumenEstancia');
                                return;
                            }

                            if ($scope.estadoReserva > 0 && $scope.documentoFirmado === 0 && firmarDocumento == 1) {
                                $location.path('/firmaElectronica');
                                return;
                            }

                        });
                }

                checkExit();

                //Actualizamos la etapa en la que estamos
                serveiApi.setEtapaPago($scope.numReserva, 1).then(function (data) {
                });


                //include de les css personalitzades de cada camping
                $css.add('../' + serveiScopeShare.getIdCamping() + '_upload/custom_' + serveiScopeShare.getIdCamping() + '.css');
                $scope.idioma = serveiScopeShare.getIdioma();

                //recupero la traduccio del text segons el idioma del navegador
                serveiTraduccions.getTranslation($scope, 'pago', serveiScopeShare.getIdioma());
                $scope.transGeneral = serveiScopeShare.getTraduccionsGeneral();
                $scope.peuPagina = serveiScopeShare.getPeuPagina();
                $scope.descTPV = serveiScopeShare.getDescTPV();
                $scope.hayCruzada = serveiScopeShare.getHayVentaCruzada();
                $scope.infoTPV = serveiScopeShare.getInfoTPV();
                $scope.numIdioma = serveiScopeShare.getNumIdioma();
                $scope.rutaLogo = serveiScopeShare.getLogoCamping();
                $scope.nomCamping = serveiScopeShare.getNomCamping();
                $scope.pagoObligado = serveiScopeShare.getPagoObligado();

                //recupero el idioma del navegador del client
                var idCamping = serveiScopeShare.getIdCamping();

                $scope.rutaTeminos = '../' + idCamping + '_upload/cond' + serveiScopeShare.getIdioma() + '.pdf';
                emplenarMesosAnys();

                //recupero els paisos i les provincies per poder parsejar el xml despres
                recuperarCamps();

                recuperarPreguntasRGPD();
            });
    }

    function procesarReserva() {

        try {
            var cliente = serveiScopeShare.getInfoClientePrincipal();
            var acompanantes = serveiScopeShare.getInfoAcompanantes();
            var vehiculos = serveiScopeShare.getInfoVehiculos();

            //recupero la reserva
            var reserva = serveiScopeShare.getInfoReserva();
            $scope.objectReserva = angular.copy(reserva[6]);

            var d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            $scope.fechaHoy = [year, month, day].join('');

            //Si no hay que pagar añado ya la información
            if (!$scope.hayQuePagar && $scope.hayCruzada) {
                //Sumo al saldo el precio de prestaciones para el resumen
                $scope.objectReserva['Estancia']['Importes']['Saldo'] = $scope.objectReserva['Estancia']['Importes']['Saldo'].replace(',', '.');
                $scope.objectReserva['Estancia']['Importes']['Saldo'] = parseFloat($scope.objectReserva['Estancia']['Importes']['Saldo']);
                $scope.objectReserva['Estancia']['Importes']['Saldo'] += parseFloat(serveiScopeShare.getTotalPrestaciones());
                $scope.objectReserva['Estancia']['Importes']['Saldo'] = String($scope.objectReserva['Estancia']['Importes']['Saldo']).replace('.', ',');
            }

            //empleno el cliente
            $scope.objectReserva['Cliente']['Apellidos'] = cliente['apellidos'];
            $scope.objectReserva['Cliente']['Nombre'] = cliente['nombre'];
            $scope.objectReserva['Cliente']['Email'] = cliente['email'];
            $scope.objectReserva['Cliente']['Fechanacimiento'] = cliente['fechaNacimiento'];
            $scope.objectReserva['Cliente']['Sexo'] = cliente['sexo'];
            $scope.objectReserva['Cliente']['Telefono1'] = cliente['telefono1'];
            $scope.objectReserva['Cliente']['Telefono2'] = cliente['telefono2'];
            $scope.objectReserva['Cliente']['LugarNacimiento'] = cliente['lugarNacimiento'];

            $scope.objectReserva['Cliente']['DNI']['FechaCaducidad'] = cliente['fechaCaducidad'];
            $scope.objectReserva['Cliente']['DNI']['FechaExpedicion'] = cliente['fechaExpedicion'];
            $scope.objectReserva['Cliente']['DNI']['Numero'] = cliente['numeroDocumento'];
            $scope.objectReserva['Cliente']['DNI']['Tipo'] = cliente['tipoDocumento'];
            $scope.objectReserva['Cliente']['DNI']['Nacionalidad']['Codigo'] = cliente['nacionalidad'];
            $scope.objectReserva['Cliente']['DNI']['Soporte'] = cliente['soporte'];

            $scope.objectReserva['Cliente']['Direccion']['CodigoPostal'] = cliente['codigoPostal'];
            $scope.objectReserva['Cliente']['Direccion']['Direccion1'] = cliente['direccion'];
            $scope.objectReserva['Cliente']['Direccion']['Direccion2'] = cliente['direccion2'];
            $scope.objectReserva['Cliente']['Direccion']['Pais']['Codigo'] = cliente['pais'];
            $scope.objectReserva['Cliente']['Direccion']['Poblacion'] = cliente['poblacion'];
            $scope.objectReserva['Cliente']['Direccion']['Provincia']['Codigo'] = cliente['provincia'];
            $scope.objectReserva['Cliente']['Direccion']['Provincia']['CheckinNuevo'] = "1";
            $scope.objectReserva['Cliente']['Contribuyente'] = cliente['contribuyente'];
            $scope.objectReserva['Cliente']['Numero'] = (parseInt(cliente['numero']) * -1);

            if (cliente['pais'] != '') { //busco el nom del pais que correspon al codi
                for (var i = 0; i < $scope.llistaPaisos.length; i++) {
                    if ($scope.llistaPaisos[i]['CodigoUnicamp'] == cliente['pais']) {
                        $scope.objectReserva['Cliente']['Direccion']['Pais']['Nombre'] = $scope.llistaPaisos[i]['Descripcion'];
                        break;
                    }
                }
            }

            if (cliente['nacionalidad'] != '') { //busco el nom del pais que correspon al codi
                for (var i = 0; i < $scope.llistaPaisos.length; i++) {
                    if ($scope.llistaPaisos[i]['CodigoUnicamp'] == cliente['nacionalidad']) {
                        $scope.objectReserva['Cliente']['DNI']['Nacionalidad']['Nombre'] = $scope.llistaPaisos[i]['Descripcion'];
                        break;
                    }
                }
            }

            if (cliente['provincia'] != '') { //busco el nom de la provincia a la que correspon el codi
                for (var i = 0; i < $scope.llistaProvincies.length; i++) {
                    if ($scope.llistaProvincies[i]['CodigoUnicamp'] == cliente['provincia']) {
                        $scope.objectReserva['Cliente']['Direccion']['Provincia']['Nombre'] = $scope.llistaProvincies[i]['Descripcion'];
                        break;
                    }
                }
            }

            if (!(Object.prototype.toString.call($scope.objectReserva['PersonasEstancia']['Persona']) === '[object Array]')) {
                //Si no es array el fem array d'una posició
                $scope.objectReserva['PersonasEstancia']['Persona'] = [$scope.objectReserva['PersonasEstancia']['Persona']];
            }

            //empleno els acompanantes
            for (var i = 0; i < acompanantes.length; i++) {

                $scope.objectReserva['PersonasEstancia']['Persona'][i]['Nombre'] = acompanantes[i]['nombre'];
                $scope.objectReserva['PersonasEstancia']['Persona'][i]['Apellidos'] = acompanantes[i]['apellidos'];
                $scope.objectReserva['PersonasEstancia']['Persona'][i]['Email'] = acompanantes[i]['email'];
                $scope.objectReserva['PersonasEstancia']['Persona'][i]['Fechanacimiento'] = acompanantes[i]['fechaNacimiento'];

                $scope.objectReserva['PersonasEstancia']['Persona'][i]['Sexo'] = acompanantes[i]['sexo'];

                $scope.objectReserva['PersonasEstancia']['Persona'][i]['DNI']['FechaCaducidad'] = acompanantes[i]['fechaCaducidad'];
                $scope.objectReserva['PersonasEstancia']['Persona'][i]['DNI']['FechaExpedicion'] = acompanantes[i]['fechaExpedicion'];
                $scope.objectReserva['PersonasEstancia']['Persona'][i]['DNI']['Numero'] = acompanantes[i]['numeroDocumento'];
                $scope.objectReserva['PersonasEstancia']['Persona'][i]['DNI']['Tipo'] = acompanantes[i]['tipoDocumento'];
                $scope.objectReserva['PersonasEstancia']['Persona'][i]['DNI']['Nacionalidad']['Codigo'] = acompanantes[i]['nacionalidad'];
                $scope.objectReserva['PersonasEstancia']['Persona'][i]['DNI']['Soporte'] = acompanantes[i]['soporte'];

                $scope.objectReserva['PersonasEstancia']['Persona'][i]['Direccion']['CodigoPostal'] = acompanantes[i]['codigoPostal'];
                $scope.objectReserva['PersonasEstancia']['Persona'][i]['Direccion']['Pais']['Codigo'] = acompanantes[i]['pais'];
                $scope.objectReserva['PersonasEstancia']['Persona'][i]['Direccion']['Poblacion'] = acompanantes[i]['poblacion'];

                $scope.objectReserva['PersonasEstancia']['Persona'][i]['Numero'] = acompanantes[i]['numero'];
                $scope.objectReserva['PersonasEstancia']['Persona'][i]['Parentesco'] = acompanantes[i]['parentesco'];

                if (acompanantes[i]['pais'] != '') { //busco el nom del pais que correspon al codi
                    for (var j = 0; j < $scope.llistaPaisos.length; j++) {
                        if ($scope.llistaPaisos[j]['CodigoUnicamp'] == acompanantes[i]['pais']) {
                            $scope.objectReserva['PersonasEstancia']['Persona'][i]['Direccion']['Pais']['Nombre'] = $scope.llistaPaisos[j]['Descripcion'];
                            break;
                        }
                    }
                }

                if (acompanantes[i]['nacionalidad'] != '') { //busco el nom del pais que correspon al codi
                    for (var j = 0; j < $scope.llistaPaisos.length; j++) {
                        if ($scope.llistaPaisos[j]['CodigoUnicamp'] == acompanantes[i]['nacionalidad']) {
                            $scope.objectReserva['PersonasEstancia']['Persona'][i]['DNI']['Nacionalidad']['Nombre'] = $scope.llistaPaisos[j]['Descripcion'];
                            break;
                        }
                    }
                }
            }

            //empleno els vehicles
            if (Object.prototype.toString.call($scope.objectReserva['VehiculosEstancia']) === '[object Array]') {
                //no faig res
            } else {
                var vehiculosTemp = [];
                vehiculosTemp.push($scope.objectReserva['VehiculosEstancia']['Vehiculo']);
                $scope.objectReserva['VehiculosEstancia']['Vehiculo'] = vehiculosTemp;
            }
            $scope.objectReserva['VehiculosEstancia']['Vehiculo'] = $scope.objectReserva['VehiculosEstancia']['Vehiculo'][0];

            if (vehiculos != undefined) {
                if (vehiculos.length == 1) {

                    $scope.objectReserva['VehiculosEstancia']['Vehiculo']['Color']['Codigo'] = vehiculos[0]['color'];
                    $scope.objectReserva['VehiculosEstancia']['Vehiculo']['Marca']['Codigo'] = vehiculos[0]['marca'];
                    $scope.objectReserva['VehiculosEstancia']['Vehiculo']['Matricula'] = vehiculos[0]['matricula'];
                    $scope.objectReserva['VehiculosEstancia']['Vehiculo']['Tipo']['Codigo'] = vehiculos[0]['tipo'];
                    $scope.objectReserva['VehiculosEstancia']['Vehiculo']['Numero'] = vehiculos[0]['numero'];

                    //recupero i guardo els textos de color, marca i tipoVehiculo al $scope.objectReserva
                    $scope.objectReserva['VehiculosEstancia']['Vehiculo']['Color']['Nombre'] = vehiculos[0]['colorText'];
                    $scope.objectReserva['VehiculosEstancia']['Vehiculo']['Marca']['Marca'] = vehiculos[0]['marcaText'];
                    $scope.objectReserva['VehiculosEstancia']['Vehiculo']['Tipo']['Nombre'] = vehiculos[0]['tipoText'];
                } else {
                    for (var i = 0; i < vehiculos.length; i++) {

                        $scope.objectReserva['VehiculosEstancia']['Vehiculo'][i]['Color']['Codigo'] = vehiculos[i]['color'];
                        $scope.objectReserva['VehiculosEstancia']['Vehiculo'][i]['Marca']['Codigo'] = vehiculos[i]['marca'];
                        $scope.objectReserva['VehiculosEstancia']['Vehiculo'][i]['Matricula'] = vehiculos[i]['matricula'];
                        $scope.objectReserva['VehiculosEstancia']['Vehiculo'][i]['Tipo']['Codigo'] = vehiculos[i]['tipo'];
                        $scope.objectReserva['VehiculosEstancia']['Vehiculo'][i]['Numero'] = vehiculos[i]['numero'];

                        //recupero i guardo els textos de color, marca i tipoVehiculo al $scope.objectReserva
                        $scope.objectReserva['VehiculosEstancia']['Vehiculo'][i]['Color']['Nombre'] = vehiculos[i]['colorText'];
                        $scope.objectReserva['VehiculosEstancia']['Vehiculo'][i]['Marca']['Marca'] = vehiculos[i]['marcaText'];
                        $scope.objectReserva['VehiculosEstancia']['Vehiculo'][i]['Tipo']['Nombre'] = vehiculos[i]['tipoText'];
                    }
                }
            }

            //Empleno les prestacions
            if ($scope.hayCruzada) {
                var prestaciones = serveiScopeShare.getPrestaciones();
                for (var i = 0; i < prestaciones.length; i++) {
                    if (prestaciones[i].seleccionada) {
                        if ($scope.objectReserva['Prestaciones'] == undefined) {
                            $scope.objectReserva['Prestaciones'] = {};
                            $scope.objectReserva['Prestaciones']['Prestacion'] = [];
                        }
                        $scope.objectReserva['Prestaciones']['Prestacion'].push(
                            {
                                Id: prestaciones[i].id,
                                Precio: prestaciones[i].precio,
                                Nombre: prestaciones[i].nombre,
                                Tipo: prestaciones[i].tipo,
                                Cantidad: prestaciones[i].seleccionada,
                                Total: prestaciones[i].total
                            });
                    }
                }
                //Si hay alguna prestacion añado total
                if ($scope.objectReserva['Prestaciones'] != undefined) {
                    $scope.objectReserva['TotalPrestaciones'] = serveiScopeShare.getTotalPrestaciones();
                }
            }

            $scope.objectReserva['VersionCheckin'] = "1.0";

            delete $scope.objectReserva.AcompanantesCliente;
        } catch (err) {
            alert($scope.translation.errorProcesar);
        }

        // guardarXML(0);
        $scope.procesadoOK = true;
    }

    function procesarPago() {

        if ($scope.hayQuePagar) {

            var codigoPago = serveiScopeShare.getCodigoPago();

            if (codigoPago['CodigoPago'] != "3") {

                var tarjetaBancaria = null;
                var tpv = null;
                if ($scope.modosPagoRepeat != undefined) {
                    tarjetaBancaria = $scope.modosPagoRepeat[parseInt($scope.indexSeleccionat)]['TarjetaBancaria'];
                }

                if ($scope.modosPagoRepeat[parseInt($scope.indexSeleccionat)]['mostrarTPV']) {
                    $scope.objectReserva['Pago']['ModoPago'] = $scope.modosPagoRepeat[parseInt($scope.indexSeleccionat)]['CodigoUnicamp'];
                } else {
                    if (parseInt(tarjetaBancaria)) { //poso datos de la tarjeta bancaria a la reserva

                        //Ponemos formato de caducidad que espera unicamp
                        if ($scope.month.substring) {
                            if ($scope.month.length < 2)
                                $scope.month = '0' + $scope.month;
                        } else if (!isNaN($scope.month)) {
                            if ($scope.month < 10)
                                $scope.month = '0' + $scope.month;
                        }

                        // $scope.year = $scope.year.slice(- 2);
                        $scope.year -= 2000; //Es un entero!!!

                        $scope.objectReserva['Pago']['ModoPago'] = $scope.modosPagoRepeat[parseInt($scope.indexSeleccionat)]['CodigoUnicamp'];
                        $scope.objectReserva['Pago']['Caducidad'] = $scope.year + '/' + $scope.month;
                        $scope.objectReserva['Pago']['Codigo'] = $scope.codigoSeguridad;
                        $scope.objectReserva['Pago']['Fecha'] = $scope.fechaHoy;
                        $scope.objectReserva['Pago']['Importe'] = $scope.apagar.replace(',', '.');
                        $scope.objectReserva['Pago']['NumeroTarjeta'] = $scope.numero;
                        $scope.objectReserva['Pago']['Titular'] = $scope.nombreTitular;
                    } else { //nomes poso el codi de pagament a la reserva
                        $scope.objectReserva['Pago']['ModoPago'] = $scope.modosPagoRepeat[parseInt($scope.indexSeleccionat)]['CodigoUnicamp'];
                        $scope.objectReserva['Pago']['Importe'] = $scope.apagar.replace(',', '.');
                        $scope.objectReserva['Pago']['Fecha'] = $scope.fechaHoy;
                    }
                }

                if (!$scope.pagoProcesado) {
                    //Passo els string dels valors a float
                    if ($scope.objectReserva['Estancia']['Importes']['Pagado'].substr()) {
                        $scope.objectReserva['Estancia']['Importes']['Pagado'] = $scope.objectReserva['Estancia']['Importes']['Pagado'].replace(',', '.');
                        $scope.objectReserva['Estancia']['Importes']['Pagado'] = parseFloat($scope.objectReserva['Estancia']['Importes']['Pagado']);
                    }
                    if ($scope.objectReserva['Estancia']['Importes']['Pedidos'].substr()) {
                        $scope.objectReserva['Estancia']['Importes']['Pedidos'] = $scope.objectReserva['Estancia']['Importes']['Pedidos'].replace(',', '.');
                        $scope.objectReserva['Estancia']['Importes']['Pedidos'] = parseFloat($scope.objectReserva['Estancia']['Importes']['Pedidos']);
                    }
                    if ($scope.objectReserva['Estancia']['Importes']['Saldo'].substr()) {
                        $scope.objectReserva['Estancia']['Importes']['Saldo'] = $scope.objectReserva['Estancia']['Importes']['Saldo'].replace(',', '.');
                        $scope.objectReserva['Estancia']['Importes']['Saldo'] = parseFloat($scope.objectReserva['Estancia']['Importes']['Saldo']);
                    }

                    //Calculo
                    $scope.objectReserva['Estancia']['Importes']['Pagado'] += parseFloat($scope.apagar.replace(',', '.'));
                    $scope.objectReserva['Estancia']['Importes']['Saldo'] -= parseFloat($scope.apagar.replace(',', '.'));
                    //Sumo prestaciones porque no computan para el saldo pero se incluyen en el total pagado
                    if ($scope.hayCruzada) {
                        $scope.objectReserva['Estancia']['Importes']['Saldo'] += parseFloat(serveiScopeShare.getTotalPrestaciones());
                    }
                    //Redondeo
                    $scope.objectReserva['Estancia']['Importes']['Pagado'] = +$scope.objectReserva['Estancia']['Importes']['Pagado'].toFixed(2);
                    $scope.objectReserva['Estancia']['Importes']['Saldo'] = +$scope.objectReserva['Estancia']['Importes']['Saldo'].toFixed(2);
                    if ($scope.objectReserva['Estancia']['Importes']['Saldo'] == -0) {
                        $scope.objectReserva['Estancia']['Importes']['Saldo'] = 0;
                    }


                    //Els torno a passar a string per a mostrar per pantalla
                    $scope.objectReserva['Estancia']['Importes']['Pagado'] = String($scope.objectReserva['Estancia']['Importes']['Pagado']).replace('.', ',');
                    $scope.objectReserva['Estancia']['Importes']['Pedidos'] = String($scope.objectReserva['Estancia']['Importes']['Pedidos']).replace('.', ',');
                    $scope.objectReserva['Estancia']['Importes']['Saldo'] = String($scope.objectReserva['Estancia']['Importes']['Saldo']).replace('.', ',');
                }

            }
        }
        $scope.pagoProcesado = true;
    }

    function guardarXML(estadoReserva) {

        if ($scope.horaLlegada != undefined && $scope.minutosLlegada != undefined) {
            $scope.objectReserva.HoraLlegada = $scope.horaLlegada + $scope.minutosLlegada
        }
        if ($scope.comentarios != undefined && $scope.comentarios != "") {
            $scope.objectReserva.Comentario = $scope.comentarios;
        }

        if ($scope.respuestasRGPD != undefined && $scope.respuestasRGPD.length > 0) {
            var respuestasRGPD = {"Respuesta": []};
            for (var i = 0; i < $scope.respuestasRGPD.length; i++) {
                if ($scope.respuestasRGPD[i] === true) {
                    respuestasRGPD.Respuesta.push({"Numero": (i + 1), "Respuesta": "2"});
                } else if ($scope.respuestasRGPD[i] === false) {
                    respuestasRGPD.Respuesta.push({"Numero": (i + 1), "Respuesta": "1"});
                } else {
                    respuestasRGPD.Respuesta.push({"Numero": (i + 1), "Respuesta": "0"});
                }
            }
            $scope.objectReserva.RespuestasRGPD = respuestasRGPD;
        }

        var xml = json2xml(eval($scope.objectReserva));
        if (xml == undefined || xml == "") {
            alert($scope.translation.errorProcesar);
            return;
        }
        xml = '<?xml version="1.0" encoding="ISO-8859-1"?><ROOT>' + xml + '</ROOT>';

        //recupero els camps obligatoris del client principal segons el camping i llistat de paisos
        var idCamping = serveiScopeShare.getIdCamping();
        var mail = serveiScopeShare.getMailCliente();
        var idReserva = serveiScopeShare.getIdReserva();
        var fecha = moment().format('YYYYMMDDHHmmss');

        var firmarCheckin = serveiScopeShare.getFirmarDocumentoCamping();

        serveiApi.guardarXMLReserva(idCamping, mail, idReserva, xml, fecha, estadoReserva).then(function (data) {
            if (estadoReserva === 1 && firmarCheckin === 1) {
                // $location.path('/resumenEstancia');
                $location.path('/firmaElectronica');
            } else {
                $location.path('/resumenEstancia');
            }
        });
    }

    function recuperarModosPago() {

        serveiApi.modosPago(serveiScopeShare.getIdCamping()).then(function (data) {

            var codigoPago = data[1];
            var modosPago = data[0];
            //recupero la reserva
            var reserva = serveiScopeShare.getInfoReserva();
            //en funcio del codigo de pago del camping recuperem un preu o un altre
            var pedido, pagado;
            switch (codigoPago['CodigoPago']) {
                case "1":
                    //recupero el saldo
                    pedido = parseFloat(reserva[6]['Estancia']['Importes']['Saldo'].replace(",", "."));
                    // pagado = parseFloat(reserva[6]['Estancia']['Importes']['Pagado'].replace(",","."));
                    // $scope.apagar = pedido - pagado;
                    $scope.apagar = pedido;
                    if ($scope.hayCruzada) {
                        $scope.apagar += parseFloat(serveiScopeShare.getTotalPrestaciones());
                    }
                    $scope.apagar = Math.max(0, (Math.round($scope.apagar * 100) / 100).toFixed(2));
                    $scope.apagar = String($scope.apagar).replace('.', ',');

                    break;
                case "2":
                    //recupero el pedidos
                    pedido = parseFloat(reserva[6]['Estancia']['Importes']['Pedidos'].replace(",", "."));
                    pagado = parseFloat(reserva[6]['Estancia']['Importes']['Pagado'].replace(",", "."));
                    $scope.apagar = pedido - pagado;
                    if ($scope.hayCruzada) {
                        $scope.apagar += parseFloat(serveiScopeShare.getTotalPrestaciones());
                    }
                    $scope.apagar = Math.max(0, (Math.round($scope.apagar * 100) / 100).toFixed(2));
                    //Paso a string per mostrar
                    $scope.apagar = String($scope.apagar).replace('.', ',');
                    break;
                case "3":
                    //no sha de pagar res
                    $scope.apagar = "0";
                    break;
                case "4":
                    //Porcentaje de pago
                    pedido = parseFloat(reserva[6]['Estancia']['Importes']['Pedidos'].replace(",", "."));
                    pagado = parseFloat(reserva[6]['Estancia']['Importes']['Pagado'].replace(",", "."));
                    $scope.apagar = Math.max(0, pedido - pagado);
                    if ($scope.hayCruzada) {
                        $scope.apagar += parseFloat(serveiScopeShare.getTotalPrestaciones());
                    }
                    $scope.apagar = Math.max(0, (Math.round($scope.apagar * 100) / 100).toFixed(2));
                    //Paso a string per mostrar
                    $scope.apagar = String($scope.apagar).replace('.', ',');
                    break;
            }


            //Si podemos no pagar añadimos modo pago extra al final para dar la opción...
            if ($scope.pagoObligado == false) {
                var modoSinPago = {
                    CodigoUnicamp: "0",
                    Descripcion: $scope.translation.noPagar || "Pay during the stay",
                    TPV: "0",
                    TarjetaBancaria: "0",
                    Traducciones: {},
                    Comentarios: {}
                };
                // console.log('vamos un paso atras');	
                // modosPago['Modo'].push(modoSinPago);

                if (typeof modosPago['Modo'][0] == "undefined") {
                    modosPago['Modo'] = [modosPago['Modo']];
                    modosPago['Modo'].push(modoSinPago);
                } else {
                    modosPago['Modo'].push(modoSinPago);
                }
            }

            $scope.modosPago = modosPago;
            $scope.modosPagoRepeat = modosPago['Modo'];
            $scope.hayQuePagar = parseFloat($scope.apagar.replace(",", ".")) > 0;


            if ($scope.modosPagoRepeat != undefined) {

                if (!(Object.prototype.toString.call($scope.modosPagoRepeat) === '[object Array]')) {
                    //Si no es un array creo un array de 1 element
                    $scope.modosPagoRepeat = [$scope.modosPagoRepeat];
                }                

                //Comprovació TPV modos pago
                for (var i = 0; i < $scope.modosPagoRepeat.length; i++) {

                    if (parseInt($scope.modosPagoRepeat[i].TPV) > 0) {
                        var idModo = $scope.modosPagoRepeat[i].CodigoUnicamp;
                        if ($scope.infoTPV[idModo] != undefined) {
                            if (parseInt($scope.infoTPV[idModo].tipoTPV) > 0 && parseInt($scope.infoTPV[idModo].activo) > 0) {
                                $scope.modosPagoRepeat[i].mostrarTPV = true;
                                $scope.modosPagoRepeat[i].tipoTPV = $scope.infoTPV[idModo].tipoTPV;
                                $scope.modosPagoRepeat[i].codigoComercio = $scope.infoTPV[idModo].codigoComercio;
                                $scope.modosPagoRepeat[i].numTerminal = $scope.infoTPV[idModo].numTerminal;
                                $scope.modosPagoRepeat[i].SHA256 = $scope.infoTPV[idModo].SHA256;
                                $scope.modosPagoRepeat[i].codigoEntidad = $scope.infoTPV[idModo].codigoEntidad;
                                $scope.modosPagoRepeat[i].clave = $scope.infoTPV[idModo].clave;
                                $scope.modosPagoRepeat[i].urlRedsys = $scope.infoTPV[idModo].urlRedsys;
                                $scope.modosPagoRepeat[i].urlComercio = $scope.infoTPV[idModo].urlComercio;
                                $scope.modosPagoRepeat[i].urlOK = $scope.infoTPV[idModo].urlOK;
                                $scope.modosPagoRepeat[i].urlKO = $scope.infoTPV[idModo].urlKO;
                                //PaymentHub
                                $scope.modosPagoRepeat[i].apiToken = $scope.infoTPV[idModo].api_token;
                                $scope.modosPagoRepeat[i].accountId = $scope.infoTPV[idModo].account_id;
                                $scope.modosPagoRepeat[i].gatewayOptId = $scope.infoTPV[idModo].gatewayOptionId;
                            }
                        }
                    }
                    $scope.arrayDisabled.push(true);
                }

                serveiScopeShare.setModosPago(modosPago);
                serveiScopeShare.setCodigoPago(codigoPago);

                decodeComentariosServei();
                decodeComentarios();
            }

            $scope.arrayDisabled[0] = false;

            procesarReserva();
        });
    }

    $scope.anterior = function () {

        var reserva = serveiScopeShare.getInfoReserva();

        //recupero el numero de vehicles que te la estada
        var vehiculos = parseInt(reserva[6]['Estancia']['TotalVehiculos']);

        if ($scope.hayCruzada) {
            $location.path('/extras');
        } else {
            if ((vehiculos > 0) && (serveiScopeShare.getDemanarVehicles() == "1")) { //redirigim a vehiculos
                $location.path('/vehiculos');
            } else { //redirigim a persones
                $location.path('/acompanantes');
            }
        }
    };

    function decodeText() {

        for (var i = 0; i < $scope.modosPagoRepeat.length; i++) {

            if ($scope.modosPagoRepeat['Comentarios']['Comentario'] != undefined) {
                for (var x = 0; i < $scope.modosPagoRepeat['Comentarios']['Comentario'].length; x++) {
                    if ($scope.modosPagoRepeat['Comentarios']['Comentario'][x]['Traduccion'] != undefined) {
                        $scope.modosPagoRepeat['Comentarios']['Comentario'][x]['Traduccion'] = serveiDecode.decode($scope.modosPagoRepeat['Comentarios']['Comentario'][x]['Traduccion']);
                    }
                }
            }
            if ($scope.modosPagoRepeat['Traducciones']['Traduccion'] != undefined) {
                for (var z = 0; i < $scope.modosPagoRepeat['Traducciones']['Traduccion'].length; z++) {
                    if ($scope.modosPagoRepeat['Traducciones']['Traduccion'][z]['Traduccion'] != undefined) {
                        $scope.modosPagoRepeat['Traducciones']['Traduccion'][z]['Traduccion'] = serveiDecode.decode($scope.modosPagoRepeat['Traducciones']['Traduccion'][z]['Traduccion']);
                    }
                }
            }
        }
    }

    function emplenarMesosAnys() {

        for (var i = 1; i <= 12; i++) {
            $scope.meses.push(i);
        }

        var d = new Date();
        $scope.yearActual = d.getFullYear();
        $scope.monthActual = d.getMonth();

        for (var i = 0; i < 15; i++) {
            $scope.years.push($scope.yearActual + i);
        }
    }

    $scope.changeSelectedRadiobutton = function (index) {

        for (var i = 0; i < $scope.arrayDisabled.length; i++) {
            $scope.arrayDisabled[i] = true;
        }

        $scope.indexSeleccionat = index;
        $scope.arrayDisabled[index] = false;

        //Si el seleccionado es el ultimo significa que es el opcional
        if ($scope.pagoObligado == false && $scope.indexSeleccionat == ($scope.arrayDisabled.length - 1)) {
            $scope.pagoOpcionalSeleccionado = true;
        } else {
            $scope.pagoOpcionalSeleccionado = false;
        }

    };

    $scope.enviarFormTPV = function() {

        if (!$scope.condicionesVenta) {
            alert($scope.translation.pagoErrorAceptarCondiciones);
            return;
        }

        if (!checkRespuestasRGPD()) {
            return;
        }

        procesarPago();
        guardarXML(0);

        //Hasta que no hay confirmación no se guarda el XML final
        var cliente = serveiScopeShare.getInfoClientePrincipal();
        var idCamping = serveiScopeShare.getIdCamping();
        var numReserva = serveiScopeShare.getIdReserva();

        var modoPago = $scope.modosPagoRepeat[parseInt($scope.indexSeleccionat)];

        var tipoTPV = parseInt(modoPago.tipoTPV);        
        if (tipoTPV == 1 || tipoTPV == 4) {

            //REDSYS
            var auxImportes = $scope.apagar.split(",");
            var importeRedsys = auxImportes[0];

            var infoToken = false;
            if (tipoTPV == 4) {
                infoToken = true;
            }

            if (auxImportes.length > 1) {
                if (parseInt(auxImportes[1]) >= 10) {
                    importeRedsys += auxImportes[1];
                } else {
                    if (auxImportes[1].substr(0, 1) === "0") {
                        importeRedsys += auxImportes[1];
                    } else {
                        importeRedsys += auxImportes[1] + "0";
                    }
                }
            } else {
                importeRedsys += "00";
            }

            var parametres = $.param({
                idCamping: idCamping,
                numReserva: numReserva,
                correo: serveiScopeShare.getMailCliente(),
                importe: importeRedsys,
                tipoTPV: 1
            });
            serveiApi.peticionTPV(parametres)
                .then(function (data) {
                    if (parseInt(data) > 0) {
                        desactivarAviso();
                        redirigirRedsys(data, importeRedsys, infoToken);                        
                    } else {
                    }
                })
                .catch(function (err) {
                });

        } else if (tipoTPV == 2) {
            //CECA
            alert($scope.translation.errorTPV);

        } else if (tipoTPV == 3) {

            //HiPay
            var importeHiPay = $scope.apagar.replace(",", ".");
            var parametres = $.param({
                idCamping: idCamping,
                numReserva: numReserva,
                correo: serveiScopeShare.getMailCliente(),
                importe: importeHiPay,
                tipoTPV: 3
            });
            $scope.showSpinner = true;
            serveiApi.peticionTPV(parametres)
                .then(function (data) {
                    if (parseInt(data) > 0) {

                        var idCompra = data;
                        var urlHiPay = modoPago.urlRedsys
                        var urlComercio = modoPago.urlComercio + "?idCamping=" + idCamping + "&modoPago=" + modoPago.CodigoUnicamp;
                        var urlOK = modoPago.urlOK + idCamping + "/" + serveiScopeShare.getMailCliente() + "/" + numReserva;
                        var login = modoPago.codigoComercio;
                        var password = modoPago.clave;
                        var websiteId = modoPago.codigoEntidad;
                        var idioma = $scope.idioma;

                        serveiApi.hiPayPeticion(importeHiPay, urlComercio, urlOK, urlHiPay, idCompra, login, password, websiteId, idioma)
                            .then(function (data) {
                                if (data != null && data != undefined && data.code != undefined && parseInt(data.code) == 0) {
                                    var idTransaccion = data.idTransaccion;
                                    var urlRedirect = data.redirectUrl;
                                    desactivarAviso();
                                    redirigirHiPay(urlRedirect);
                                } else {
                                    alert($scope.translation.errorTPV);
                                    $scope.showSpinner = false;
                                }
                            })
                            .catch(function (err) {
                                $scope.showSpinner = false;
                            });

                    } else {
                        $scope.showSpinner = false;
                    }
                })
                .catch(function (err) {
                    $scope.showSpinner = false;
                });


        }else if (tipoTPV == 5) {
            //AQUI
            if (modoPago.accountId == undefined || modoPago.gatewayOptId == undefined) {
                alert($scope.translation.errorTPV);
                $scope.showSpinner = false;
            } else {
                const payerId = (parseInt(cliente['numero']) * -1);   
                var auxImportesPaymentHub = $scope.apagar.split(",");          
                var importePaymentHub = auxImportesPaymentHub[0];
                if (auxImportesPaymentHub.length > 1) {
                    if (parseInt(auxImportesPaymentHub[1]) >= 10) {
                        importePaymentHub += auxImportesPaymentHub[1];
                    } else {
                        if (auxImportesPaymentHub[1].substr(0, 1) === "0") {
                            importePaymentHub += auxImportesPaymentHub[1];
                        } else {
                            importePaymentHub += auxImportesPaymentHub[1] + "0";
                        }
                    }
                } else {
                    importePaymentHub += "00";
                }  
                const accountId = modoPago.accountId;   
                const gatewayOptionId = modoPago.gatewayOptId;
                
                var parametres = $.param({
                    idCamping: idCamping,
                    numReserva: numReserva,
                    correo: serveiScopeShare.getMailCliente(),
                    importe: importePaymentHub,
                    tipoTPV: 5
                });
                serveiApi.peticionTPV(parametres)
                    .then(function (data) {
                        if (parseInt(data) > 0) {
                            desactivarAviso();
                            const paymentHubObject = {
                                accountId: accountId,
                                gatewayOptionId: gatewayOptionId,
                                type: 'PAYMENT_REDIRECT',
                                returnUrlOk: modoPago.urlOK,
                                returnUrlKo: modoPago.urlKO,
                                payerId: payerId,
                                amount: importePaymentHub,
                                currencyId: '978',
                                orderId: data,
                                requestToken: true,
                                token: modoPago.apiToken
                            }                            
                            /**
                             * accountId OK
                             * gatewayOptionId
                             * type : PAYMENT_REDIRECT
                             * returnUrlOk
                             * returnUrlKo
                             * payerId
                             * amount
                             * currencyId
                             * orderId
                             */                            
                            //redirigirRedsys(data, importeRedsys, infoToken); 
                            redirigirPaymentHub(paymentHubObject);
                        } else {
                        }
                    })
                    .catch(function (err) {
                    });
            }            
        } else {
            alert($scope.translation.errorTPV);
            $scope.showSpinner = false;
        }
    };

//TODO function redirectPayments -> aqui es donde se redirige a la pasarela de pago
    function redirigirPaymentHub(objectData) {
        var idCamping = serveiScopeShare.getIdCamping();
        var mail = serveiScopeShare.getMailCliente();
        var idReserva = serveiScopeShare.getIdReserva();
        const parametres = $.param({
            accountId: objectData.accountId,
            gatewayOptionId: objectData.gatewayOptionId,
            type: objectData.type,
            returnUrlOk: objectData.returnUrlOk,
            returnUrlKo: objectData.returnUrlKo,
            payerId: objectData.payerId,
            amount: objectData.amount,
            currencyId: objectData.currencyId,
            orderId: objectData.orderId,
            requestToken: objectData.requestToken,
            token: objectData.token,
            idCamping: idCamping,
            mail: mail,
            idReserva: idReserva
        });    
        serveiApi.getPaymentHubLink(parametres)
            .then(function (url) {                        
                window.location.href = url;
            })
            .catch(function (err) {
                alert('Error redirecting');
                $scope.showSpinner = false;

            });
    }

    function redirigirRedsys(idCompra, importe, infoToken) {

        var idCamping = serveiScopeShare.getIdCamping();
        var mail = serveiScopeShare.getMailCliente();
        var idReserva = serveiScopeShare.getIdReserva();

        var codigoComercio = "";
        var numTerminal = "";
        var clave = "";
        var urlRedsys = "";
        var urlComercio = "";
        var urlOK = "";
        var urlKO = "";

        var modoPago = $scope.modosPagoRepeat[parseInt($scope.indexSeleccionat)];
        if (parseInt(modoPago.TPV) > 0) {

            codigoComercio = modoPago.codigoComercio;
            numTerminal = modoPago.numTerminal;
            clave = modoPago.clave;
            urlRedsys = modoPago.urlRedsys;
            urlComercio = modoPago.urlComercio + "?idCamping=" + idCamping + "&modoPago=" + modoPago.CodigoUnicamp;
            urlOK = modoPago.urlOK;
            urlKO = modoPago.urlKO;

            if (parseInt(modoPago.SHA256) == 1) {

                var url = './api/tpv/redsysPeticion.php';
                var form = '<form action="' + url + '" method="post">' +
                    '<input type="hidden" name="idCompra" value="' + idCompra + '" />' +
                    '<input type="hidden" name="importe" value="' + importe + '" />' +
                    '<input type="hidden" name="camping" value="' + idCamping + '" />' +
                    '<input type="hidden" name="reserva" value="' + idReserva + '" />' +
                    '<input type="hidden" name="mail" value="' + mail + '" />' +
                    '<input type="hidden" name="fuc" value="' + codigoComercio + '" />' +
                    '<input type="hidden" name="terminal" value="' + numTerminal + '" />' +
                    '<input type="hidden" name="clave" value="' + clave + '" />' +
                    '<input type="hidden" name="urlRedsys" value="' + urlRedsys + '" />' +
                    '<input type="hidden" name="urlComercio" value="' + urlComercio + '" />' +
                    '<input type="hidden" name="urlOK" value="' + urlOK + '" />' +
                    '<input type="hidden" name="urlKO" value="' + urlKO + '" />';

                if (infoToken) {
                    form += '<input type="hidden" name="infoToken" value="' + infoToken + '" />';
                }

                form += '</form>';
                form = $(form);
                $('body').append(form);
                form.submit();
            } else {
                alert($scope.translation.errorTPV);
            }
        } else {
            alert($scope.translation.errorTPV);
        }
    }

    function redirigirHiPay(url) {

        var form = $('<form action="' + url + '" method="get">' + '</form>');
        $('body').append(form);
        form.submit();
    }

    $scope.validarForm = function (form) {

        //No entenc per què es treballa amb el valid del form si esta desactivat amb el novalidate!!!
        form.$valid = true;

        var cliente = JSON.parse(localStorage.getItem('cliente'));
        var acompanantes = JSON.parse(localStorage.getItem('acompanantes'));

        //arreglo els camps dels inputs numerics cuan es modifiquen i posteriorment es borren
        if ((isNaN($scope.codigoSeguridad)) || ($scope.codigoSeguridad == undefined)) {
            $scope.codigoSeguridad = undefined;
            form.$valid = false;
            $scope.errorCodSeg = true;
        } else {
            if (String($scope.codigoSeguridad).length != 3) {
                //alert('El código de seguridad debe ser de 3 dígitos');
                form.$valid = false;
                $scope.errorCodSeg = true;
            } else {
                $scope.errorCodSeg = false;
            }
        }

        if ($scope.numero == undefined) {
            $scope.numero = undefined;
            form.$valid = false;
        } else if (isNaN($scope.numero) || String($scope.numero).length != 16) {
            form.$valid = false;
            $scope.errorNumero = true;
        } else {
            $scope.errorNumero = false;
        }

        // comprobo que la longitud del codi de seguretat sigui 3
        // if ($scope.codigoSeguridad != undefined) {
        //  if (String($scope.codigoSeguridad).length != 3) {
        //      alert('El código de seguridad debe ser de 3 dígitos');
        //      form.$valid = false;
        //  }
        // }

        //comprobacio de que la data de caducitat es posterior a la data d'avui
        if (($scope.year == $scope.yearActual) && ($scope.month <= $scope.monthActual)) {
            alert($scope.translation.pagoErrorFechaCaducidad);
            form.$valid = false;
        }

        if (!$scope.condicionesVenta) {
            alert($scope.translation.pagoErrorAceptarCondiciones);
            form.$valid = false;
        }

        if (!checkRespuestasRGPD()) {
            form.$valid = false;
        }

        if (form.$valid) {
            procesarPago();
            guardarXML(1);
        } else {
        }
    };

    $scope.validarTransferencia = function () {

        if ($scope.condicionesVenta) {

            if (!checkRespuestasRGPD()) {
                return;
            }

            if ($scope.pagoObligado == false && $scope.pagoOpcionalSeleccionado) {
                $scope.hayQuePagar = false;
                $scope.apagar = 0;
            }

            procesarPago();
            guardarXML(1);
        } else {
            alert($scope.translation.pagoErrorAceptarCondiciones);
        }
    };

    $scope.checkUndefined = function (valor) {

        if ((valor == undefined) || (valor == "") || (valor == 0)) {
            return true;
        } else {
            return false;
        }
    };


    function checkRespuestasRGPD() {

        if ($scope.preguntasRGPD != undefined && $scope.preguntasRGPD.length > 0) {
            for (var i = 0; i < $scope.preguntasRGPD.length; i++) {
                if ($scope.preguntasRGPD[i].Configurada == 1 && $scope.respuestasRGPD[i] == undefined) {
                    alert("Debe responder a las preguntas de privacidad")
                    return false;
                }
            }
        }
        return true;
    }

    function recuperarCamps() {

        var idCamping = serveiScopeShare.getIdCamping();

        serveiApi.camposObligatoriosCliente(idCamping).then(function (data) {

            $scope.llistaPaisos = data[1]['Pais'];
            $scope.llistaProvincies = data[2]['Provincia'];

            serveiApi.getMailContactoCamping(idCamping).then(function (data) {
                $scope.emailCamping = data;
            });
            serveiApi.getTextoTransferencia(idCamping, serveiScopeShare.getIdioma()).then(function (data) {
                $scope.textoTransferencia = data;
            });
            recuperarModosPago();
        });
    }

    function recuperarPreguntasRGPD() {

        serveiApi.getPreguntasRGPD(serveiScopeShare.getIdCamping()).then(function (data) {
            if (data != undefined && data.Pregunta != undefined) {
                $scope.preguntasRGPD = data.Pregunta;

                for (var i = 0; i < $scope.preguntasRGPD.length; i++) {
                    $scope.respuestasRGPD.push(undefined);
                }
            }
        });

    }

    function decodeProvincies() {
        for (var i = 0; i < $scope.llistaProvincies.length; i++) {
            $scope.llistaProvincies[i]['Descripcion'] = serveiDecode.decode($scope.llistaProvincies[i]['Descripcion']);
        }
    }

    function decodePaisos() {
        for (var i = 0; i < $scope.llistaPaisos.length; i++) {
            $scope.llistaPaisos[i]['Descripcion'] = serveiDecode.decode($scope.llistaPaisos[i]['Descripcion']);
        }
    }

    function decodeComentarios() {

        if ($scope.modosPagoRepeat != undefined) {
            for (var i = 0; i < $scope.modosPagoRepeat.length; i++) {

                if ($scope.modosPagoRepeat[i]['Comentarios']['Comentario'] != undefined) {
                    for (var x = 0; x < $scope.modosPagoRepeat[i]['Comentarios']['Comentario'].length; x++) {
                        if ($scope.modosPagoRepeat[i]['Comentarios']['Comentario'][x]['Traduccion'] != undefined) {
                            $scope.modosPagoRepeat[i]['Comentarios']['Comentario'][x]['Traduccion'] = serveiDecode.decode($scope.modosPagoRepeat[i]['Comentarios']['Comentario'][x]['Traduccion']);
                        }
                    }
                }

                if ($scope.modosPagoRepeat[i]['Traducciones']['Traduccion'] != undefined) {
                    for (var x = 0; x < $scope.modosPagoRepeat[i]['Traducciones']['Traduccion'].length; x++) {
                        if ($scope.modosPagoRepeat[i]['Traducciones']['Traduccion'][x]['Traduccion'] != undefined) {
                            $scope.modosPagoRepeat[i]['Traducciones']['Traduccion'][x]['Traduccion'] = serveiDecode.decode($scope.modosPagoRepeat[i]['Traducciones']['Traduccion'][x]['Traduccion']);
                        }
                    }
                }
            }
        }
    }

    function decodeComentariosServei() {

        if ($scope.modosPagoRepeat != undefined) {
            for (var i = 0; i < $scope.modosPagoRepeat.length; i++) {

                if ($scope.modosPagoRepeat[i]['Comentarios']['Comentario'] != undefined) {
                    for (var x = 0; x < $scope.modosPagoRepeat[i]['Comentarios']['Comentario'].length; x++) {
                        if ($scope.modosPagoRepeat[i]['Comentarios']['Comentario'][x]['Traduccion'] != undefined) {
                            $scope.modosPagoRepeat[i]['Comentarios']['Comentario'][x]['Traduccion'] = serveiDecode.Utf8Encode($scope.modosPagoRepeat[i]['Comentarios']['Comentario'][x]['Traduccion']);
                        }
                    }
                }

                if ($scope.modosPagoRepeat[i]['Traducciones']['Traduccion'] != undefined) {
                    for (var x = 0; x < $scope.modosPagoRepeat[i]['Traducciones']['Traduccion'].length; x++) {
                        if ($scope.modosPagoRepeat[i]['Traducciones']['Traduccion'][x]['Traduccion'] != undefined) {
                            $scope.modosPagoRepeat[i]['Traducciones']['Traduccion'][x]['Traduccion'] = serveiDecode.Utf8Encode($scope.modosPagoRepeat[i]['Traducciones']['Traduccion'][x]['Traduccion']);
                        }
                    }
                }
            }
        }
    }

    function desactivarAviso() {
        window.removeEventListener("beforeunload", eventHandler, false);
    }

    var xml_special_to_escaped_one_map = {
        '&': '&amp;',
        '"': '&quot;',
        '<': '&lt;',
        '>': '&gt;'
    };

    function encodeXml(string) {
        return string.replace(/([\&"<>])/g, function (str, item) {
            return xml_special_to_escaped_one_map[item];
        });
    };

    function json2xml(o, tab) {

        var toXml = function (v, name, ind) {
            var xml = "";
            if (v instanceof Array) {
                for (var i = 0, n = v.length; i < n; i++)
                    xml += ind + toXml(v[i], name, ind + "\t") + "\n";
            } else if (typeof (v) == "object") {
                var hasChild = false;
                xml += ind + "<" + name;
                for (var m in v) {
                    if (m.charAt(0) == "@")
                        xml += " " + m.substr(1) + "=\"" + encodeXml(v[m].toString()) + "\"";
                    else
                        hasChild = true;
                }
                xml += hasChild ? ">" : "/>";
                if (hasChild) {
                    for (var m in v) {
                        if (m == "#text")
                            xml += v[m];
                        else if (m == "#cdata")
                            xml += "<![CDATA[" + v[m] + "]]>";
                        else if (m.charAt(0) != "@")
                            xml += toXml(v[m], m, ind + "\t");
                    }
                    xml += (xml.charAt(xml.length - 1) == "\n" ? ind : "") + "</" + name + ">";
                }
            } else {
                if (v != undefined) {
                    xml += ind + "<" + name + ">" + encodeXml(v.toString()) + "</" + name + ">";
                } else {
                    xml += ind + "<" + name + "></" + name + ">";
                }
            }
            return xml;
        }, xml = "";
        for (var m in o)
            xml += toXml(o[m], m, "");
        return tab ? xml.replace(/\t/g, tab) : xml.replace(/\t|\n/g, "");

    }

    var eventHandler;

    function checkExit() {
        eventHandler = function (e) {
            var confirmationMessage = $scope.translation.resumenCloseMessage;
            e.returnValue = confirmationMessage;
            return confirmationMessage;
        };
        window.addEventListener("beforeunload", eventHandler, false);
    }

    $scope.$on('$locationChangeStart', function (event) {
        window.removeEventListener("beforeunload", eventHandler, false);
    });

}])
;
